import * as React from 'react'
import css from '@emotion/css'
import { mediaQueries } from '$theme/legacy'
import { rhythm } from '$utils/typography'

type Props = {
  children?: React.ReactNode
}

function Page(props: Props) {
  return (
    <div
      css={css`
				margin: 0 auto;
				padding: 50px auto;
				width: 100%;
				padding: ${rhythm(1)} 20px;
				${mediaQueries.MIN_DEFAULT_MEDIA_QUERY} {
					margin: 0 auto;
					padding: 62px auto;
					max-width: 1280px;
					/* padding: ${rhythm(1)} 20px; */
				}
			`}
    >
      {props.children}
    </div>
  )
}

export default Page
