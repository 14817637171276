import React from 'react'
import { Helmet } from 'react-helmet'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { rhythm } from '$utils/typography'
import Page from '$components/Page'
import Text from '$components/Text'
import Layout from '$components/Layout'

const Container = styled.div`
  width: 100%;
  margin: 10px auto;
  padding: 0 10px;
  height: 100%;
`

function About() {
  return (
    <Layout>
      <Page>
        <Helmet title={'About'} />
        <Container>
          <div
            css={css`
              ${rhythm(1)}
            `}
          >
            <Text size={Text.Sizes.Large} weight={Text.Weights.Bold}>
              About Scope Travel
            </Text>
          </div>
          <Text size={Text.Sizes.Normal}>In progress ...</Text>
          {/* <Bio>
						<Person>
							<div>
								<Text>Sydney</Text>
							</div>
							<Picture
								url={
									'https://s3.amazonaws.com/scope-ghost/uploader/sydney-profile-squats.jpg'
								}
								size={'medium'}
							/>
						</Person>
						<Person>
							<Text>Gabe</Text>
							<Picture
								url={
									'https://s3.amazonaws.com/s3.scope.travel/uploader/viedma-glacier-el-chalten-argentina-patagonia-tunnel-gabe-selfie.jpg'
								}
								size={'medium'}
							/>
						</Person>
					</Bio> */}
        </Container>
      </Page>
    </Layout>
  )
}

export default About
